.close {
    color: #fff;
    font-size: 28px;
    line-height: 40px;
    font-weight: normal;
    text-align: right;
    height: 40px;
    width: 40px; //opacity: 1;
    @include hover-focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    } // Opinionated: add "hand" cursor to non-disabled .close elements
    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
}

.popup_login_btn {
    background-color: #ec2327;
    font-size: 12px;
    width: 84px;
    height: 27px;
    line-height: 29px;
    border-radius: 30px;
    box-shadow: 1px 3px #c01b1e;
    text-decoration: none;
    margin: 20px 0 0 96px;
    padding: 0 10px;
    color: #fff;
    text-align: center;
    float: left;
}

.popup_login_btn:hover {
    background-color: #c01b1e;
}

.popup_acc_btn {
    background-color: #ec2327;
    font-size: 12px;
    width: 120px;
    height: 27px;
    line-height: 29px;
    border-radius: 30px;
    box-shadow: 1px 3px #c01b1e;
    text-decoration: none;
    margin: 20px 0 0 120px;
    padding: 0 10px;
    color: #fff;
    text-align: center;
    float: left;
}

.popup_acc_btn:hover {
    background-color: #c01b1e;
}

.modal_top_bg {
    padding: 0px 10px;
    background-color: #2d2928;
    border-radius: 5px 5px 0px 0px;
    .modal_top-tt {
        font-size: 18px;
        line-height: 40px;
        text-align: left;
        color: #ff0000;
        font-weight: bold;
        width: 80%;
    }
}

.modalacc_top_bg {
    padding: 5px 10px;
    background-color: #2d2928;
    border-radius: 10px 10px 0px 0px;
    .modalacc_top_img {
        background: url(../img/acc_popup_tt_img.png) top center no-repeat;
        height: 35px;
        width: 86%;
        float: left;
        background-size: contain;
        margin: 4px 0 0 8%;
    }
}

.box_acc_line {
    width: 360px;
    height: 1px;
    border-bottom: 1px #666 solid;
    margin: 20px 0 10px 0;
}

.modal_acc_box {
    padding: 30px 30px;
    width: 100%;
    height: inherit;
    .acc_box1 {
        width: 252px;
        height: 432px;
        background: url(../img/acc_popup_img.png) top center no-repeat;
    }
    .acc_box2 {
        margin: 0 0 0 20px;
        width: 368px;
    }
    .modal_acc_box_tt1 {
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        color: #000;
        font-weight: bold;
    }
    .box_acc_input {
        margin: 10px 0 0 0;
        .hd_acc_input {
            border-radius: 10px;
            width: 360px;
            height: 36px;
            font-size: 16px;
            padding: 0 15px;
            background-color: #ededee;
            border: 0px;
        }
        .acc_input_hp {
            width: 300px !important;
            margin: 0 0 0 10px !important;
        }
        .acc_input_nb {
            width: 50px !important;
            text-align: center !important;
            padding: 0 5px !important;
        }
    }
}

.modal_box {
    padding: 20px 20px;
    width: 100%;
    height: 240px;
    .modal_box_tt1 {
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        color: #000;
    }
    .box_input {
        margin: 10px 10px 0 10px;
        .hd_icon01 {
            line-height: 24px !important;
            font-size: 24px !important;
            margin: 4px 0 0 0;
            width: 20px;
        }
        .hd_input {
            border-radius: 10px;
            width: 234px;
            height: 36px;
            font-size: 16px;
            padding: 0 5px;
            background-color: #ededee;
            border: 0px;
        }
    }
    .modal_box_forget {
        width: 254px;
        margin: 5px 0 0 20px;
        .box_forget_in {
            width: 130px;
            padding: 0 0 0 15px;
            input {
                margin: 2px 5px;
            }
            .box_forget_tt1 {
                text-align: left;
                font-size: 14px;
                line-height: 16px;
                color: #999999;
            }
        }
        .box_forget_tt2 {
            text-align: right;
            font-size: 12px;
            line-height: 16px;
            color: #999999;
            width: 80px;
            cursor: pointer;
        }
    }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
// stylelint-disable property-no-vendor-prefix, selector-no-qualifying-type
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

// stylelint-enable
@media screen and (max-width: 768px) {
    .popup_acc_btn {
        margin: 20px 0 0 80px;
    }
    .modalacc_top_bg {
        padding: 5px 10px;
        border-radius: 10px 10px 0px 0px;
        .modalacc_top_img {
            height: 35px;
            width: 76%;
            margin: 4px 0 0 10%
        }
    }
    .box_acc_line {
        width: 100%;
    }
    .modal_acc_box {
        padding: 20px 20px;
        width: 100%;
        height: inherit;
        .acc_box1 {
            display: none;
        }
        .acc_box2 {
            margin: 0 0;
            width: 280px;
        }
        .modal_acc_box_tt1 {
            font-size: 18px;
            line-height: 28px;
        }
        .box_acc_input {
            margin: 10px 0 0 0;
            .hd_acc_input {
                width: 100%;
                height: 36px;
                padding: 0 15px;
            }
            .acc_input_hp {
                width: 220px !important;
            }
        }
    }
}

@media screen and (max-width: 576px) {}