// stylelint-disable selector-no-qualifying-type

.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}


.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}

@media screen and (max-width: 1199px) {
  .collapse.in{
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .collapse.in{
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .collapse.in{
    display: block;
  }
}